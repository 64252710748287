import { Controller } from "stimulus";
import ReactDOM from "react-dom";
import { createElement } from "react";
import { App, ConfigProvider } from "antd";

import theme, { mobileTheme } from "theme/antd";
import { useIsMobile } from "hooks";

const exposedComponents = {
  GardenSettings: () => import("components/GardenSettings"),
  GardenSelector: () => import("components/GardenSelector"),
  CalendarSidebar: () => import("components/CalendarSidebar"),
  CalendarNavbar: () => import("components/CalendarNavbar"),
  Flash: () => import("components/Flash"),
  FlashMessages: () => import("components/FlashMessages"),
  SneakyBrowsersWarning: () => import("components/SneakyBrowsersWarning"),
  TaskFilter: () => import("components/TaskFilter"),
  ViewSelector: () => import("components/ViewSelector"),
  Profile: () => import("components/Profile"),
  YearTimelineNavigation: () => import("components/CalendarMenu/YearTimelineNavigation"),
  MonthNavigation: () => import("components/CalendarMenu/MonthNavigation"),
  Journal: () => import("components/Journal"),
  Tasks: () => import("components/Tasks"),
  CalendarTaskModal: () => import("components/CalendarTaskModal"),
  TimelineSort: () => import("components/TimelineSort"),
  Customize: () => import("components/Customize"),
  Community: () => import("components/Community"),
  GardenPlan: () => import("components/GardenPlan"),
  SignUpLocation: () => import("components/SignUpLocation"),
  UpgradeToLayersModal: () => import("components/UpgradeToLayersModal"),
  InviteFriends: () => import("components/InviteFriends"),
  Timeline: () => import("components/Timeline"),
  MobileHeader: () => import("components/MobileHeader")
};

const EntryPoint = ({ children }) => {
  const isMobile = useIsMobile();

  return (
    <ConfigProvider theme={isMobile ? mobileTheme : theme} prefixCls="ant5">
      <App>{children}</App>
    </ConfigProvider>
  );
};

export default class extends Controller {
  initialize() {
    this.elementData = this.element.dataset;
  }

  connect() {
    this.importComponent().then((component) => {
      ReactDOM.render(
        createElement(EntryPoint, {}, createElement(component, this.componentProps())),
        this.element
      );
    });
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.element);
  }

  importComponent() {
    if (!this.elementData.component) {
      throw new Error(
        `You should define the exposed component name
        via 'data-component' attribute to use this controller`
      );
    }

    if (!exposedComponents[this.elementData.component]) {
      throw new Error(
        `${this.element.getAttribute(
          "data-component"
        )} wasn't registered in exposedComponents`
      );
    }

    return exposedComponents[this.elementData.component]().then(
      (module) => module.default
    );
  }

  componentProps() {
    return JSON.parse(this.elementData.props);
  }
}
