import { CloseCircleOutlined } from "@ant-design/icons";
import { cx } from "@linaria/core";
import { styled } from "@linaria/react";
import type { ModalProps } from "antd";
import { Modal as AntdModal } from "antd";
import type { FC } from "react";

import {
  compactBodyClassName,
  compactFooterClassName,
  compactHeaderClassName,
  defaultBodyClassName,
  defaultFooterClassName,
  defaultHeaderClassName,
  fullScreenBodyClassName,
  fullScreenContentClassName,
  fullScreenHeaderClassName,
  fullScreenModalClassName
} from "./styles";

type Layout = "default" | "compact" | "fullScreen";

const CLASS_NAME_LAYOUT_MAP: Record<Layout, ModalProps["classNames"]> = {
  compact: {
    body: compactBodyClassName,
    footer: compactFooterClassName,
    header: compactHeaderClassName
  },
  default: {
    body: defaultBodyClassName,
    footer: defaultFooterClassName,
    header: defaultHeaderClassName
  },
  fullScreen: {
    body: fullScreenBodyClassName,
    content: fullScreenContentClassName,
    header: fullScreenHeaderClassName
  }
};

const StyledModal = styled(AntdModal)<ModalProps & { layout?: Layout }>`
  .ant5-modal-close {
    top: ${({ layout }) => (layout === "compact" ? "8px" : "12px")};
  }
`;

type ModalType = FC<ModalProps & { fullScreen?: boolean; layout?: Layout }> & {
  warning: typeof AntdModal.warning;
};

const Modal: ModalType = ({ layout = "default", className, width, ...props }) => {
  const isFullScreen = layout === "fullScreen";

  return (
    <StyledModal
      closeIcon={<CloseCircleOutlined />}
      layout={layout}
      classNames={CLASS_NAME_LAYOUT_MAP[layout]}
      className={cx(isFullScreen && fullScreenModalClassName, className)}
      width={isFullScreen ? "100vw" : width}
      {...props}
    />
  );
};

Modal.warning = AntdModal.warning;

export default Modal;
