import type { TaskType } from "types";
import { TaskTypes } from "types";

const TASK_NAMES: Record<TaskType | "%future added value", string> = {
  [TaskTypes.DirectSeeding]: "Direct Seed",
  [TaskTypes.IndoorSeeding]: "Seeding",
  [TaskTypes.BedPreparation]: "Bed Preparation",
  [TaskTypes.Transplanting]: "Transplanting",
  [TaskTypes.Cultivating]: "Cultivating",
  [TaskTypes.Harvesting]: "Harvesting",
  [TaskTypes.StaleSeedBed]: "Stale Seed Bed",
  [TaskTypes.Custom]: "Custom",
  "%future added value": "Unknown task type"
};

// TODO: https://seedtime.atlassian.net/browse/ST-2364 Review and improve usage of this function
const humanizeTaskType = (
  taskType: TaskType | "%future added value",
  name?: null | string
): string => name || TASK_NAMES[taskType] || TASK_NAMES["%future added value"];

export default humanizeTaskType;
