export type TaskStatus = 'completed' | 'todo'

export enum TaskTypes {
  DirectSeeding = 'direct_seeding',
  IndoorSeeding = 'indoor_seeding',
  BedPreparation = 'bed_preparation',
  Transplanting = 'transplanting',
  Cultivating = 'cultivating',
  Harvesting = 'harvesting',
  StaleSeedBed = 'stale_seed_bed',
  Custom = 'custom',
}

export type TaskType = `${TaskTypes}`

export type Task = {
  color: string
  cropId: number
  cropName: string
  endDate: string
  gardenCropId: number
  id: number
  plantingLocations: []
  plantsCount: number
  startDate: string
  status: TaskStatus
  taskCompletions: []
  taskType: TaskType
  name: string | null
}
