/* eslint-disable @typescript-eslint/no-var-requires */
// variables to use in modifyVars for less-loader
// See available variables in
// node_modules/antd/es/style/color
// node_modules/antd/es/style/themes

const colors = require("./colors");
const seedtime = require("./seedtime");

module.exports = {
  "body-background": colors.pageBackground,
  "border-color-base": colors.lightGrey,
  "border-color-split": colors.lightGrey,
  "border-radius-base": "8px",
  "border-style-base": "solid",
  "border-width-base": "1px",
  "btn-height-lg": "44px",
  "btn-font-size-lg": "16px",
  "btn-primary-bg": colors.primary,
  "font-size-base": "16px",
  "font-family": seedtime["text-font"],
  "link-color": colors.primary,
  "red-base": "#E27367",
  "error-color": colors.error,
  "margin-xlg": "32px",
  "margin-lg": "24px",
  "margin-md": "16px",
  "margin-sm": "12px",
  "margin-xs": "8px",
  "margin-xxs": "4px",
  "padding-xxl": "40px",
  "padding-xlg": "32px",
  "padding-lg": "24px",
  "padding-md": "16px",
  "padding-sm": "12px",
  "padding-xs": "8px",
  "padding-xxs": "4px",
  "primary-color": colors.primary,
  "text-color": colors.textPrimary,
  "text-color-secondary": colors.darkGrey,
  "text-color-disabled": colors.lightGrey
};
