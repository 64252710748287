import type { ComponentProps, FC, SVGProps } from "react";

import colors from "theme/colors";

import type { SizeType } from "./constants";
import { DEFAULT_SIZE, SIZES_MAP } from "./constants";

export type GlyphType<ExtraProps = Record<string, unknown>> = FC<
  SVGProps<SVGSVGElement> & ExtraProps
>;

type Props<T extends GlyphType> = {
  description?: string;
  glyph: T;
  size?: SizeType;
} & SVGProps<SVGSVGElement> &
  ComponentProps<T>;

const SvgIcon = <T extends GlyphType>({
  glyph,
  color = colors.midGrey,
  size = DEFAULT_SIZE,
  ...rest
}: Props<T>) =>
  glyph({ width: SIZES_MAP[size], height: SIZES_MAP[size], color, ...rest });

export default SvgIcon;
