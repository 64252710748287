import { css } from "@linaria/core";

import { MOBILE_MAX_SCREEN_SIZE } from "theme/antd";

export const compactBodyClassName = css`
  padding: 16px 24px !important;
`;

export const compactFooterClassName = css`
  padding: 0 18px 24px !important;
  margin: 0 !important;

  @media screen and (max-width: ${MOBILE_MAX_SCREEN_SIZE}px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;

    .ant5-btn + .ant5-btn {
      margin-inline-start: 0 !important;
    }
  }
`;

export const compactHeaderClassName = css`
  padding: 12px 24px !important;
`;

export const defaultBodyClassName = css`
  padding: 32px 40px !important;
`;

export const defaultFooterClassName = css`
  padding: 0 32px 40px !important;
`;

export const defaultHeaderClassName = css`
  padding: 20px 40px !important;
`;

export const fullScreenBodyClassName = css`
  background-color: var(--white-color);
  border-radius: 8px;
  margin: 0 16px;
  padding: 16px !important;
`;

export const fullScreenContentClassName = css`
  background-color: var(--page-background-color) !important;
  border-radius: 0 !important;

  min-height: 100vh;
  width: 100vw;
`;

export const fullScreenHeaderClassName = css`
  padding: 16px !important;
`;

export const fullScreenModalClassName = css`
  max-width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
  top: 0;
`;
